/* eslint-disable */
import Styled from 'styled-components';

import {
  VIEWPORT_XXS,
  VIEWPORT_XS,
  VIEWPORT_SM_MIN
} from '../../util/mediaQueries'


export const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1 1;
  justify-content: space-between;
  align-items:flex-end;
  width: 100%;
  margin: .5em;
`;

export const Label = Styled.div`
  color: ${props => props.theme.headlineColor};
  width: 60%;
  padding; 0;

  @media ${VIEWPORT_XXS} {
    font-size: 1.375em;
  }

  @media ${VIEWPORT_XS} {
    font-size: 1.375em;
  }

  @media ${VIEWPORT_SM_MIN} {
    font-size: 1.375em;
  }
`;

export const Seal = Styled.div`
  width: 40%;
  text-align: right;
  padding; 0;
  height: 2em;
`;
