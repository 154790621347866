import React from 'react';
import PropTypes from 'prop-types';
import Siegel from './siegel.svg';

import {
  Wrapper,
  Label,
  Seal,
} from './SealHeader.style';


/**
 * Cafe Header containing amount and
 *
 * @return {JSX}
 */
const SealHeader = ({ headline }) => (
  <Wrapper>
    <Label>{ headline }</Label>
    <Seal>
      <Siegel />
    </Seal>
  </Wrapper>
);

SealHeader.propTypes = {
  headline: PropTypes.string.isRequired,
};

export default SealHeader;
