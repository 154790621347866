import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './LoanSealForm.style';
import LoanForm from '../LoanForm/LoanForm';

import SealHeader from '../../molecules/SealHeader/SealHeader';

class LoanSealForm extends React.PureComponent {

  static propTypes = {
    affiliateData: PropTypes.object.isRequired,
  };

  render() {
    return (
      <Container>
        <SealHeader headline="Kreditrechner" />
        <LoanForm {...this.props} />
      </Container>
    );
  }

}

export default LoanSealForm;
