/* eslint-disable */
import Styled from 'styled-components';

import {
  VIEWPORT_XXS,
  VIEWPORT_XS,
  VIEWPORT_SM_MIN
} from '../../util/mediaQueries'

export const Container = Styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.25em;
  border: 0.0625em solid ${props => props.theme.borderColor};
  border-radius: 0.1em;
  padding: .5em;
  width: auto;
  background-color: ${props => props.theme.bodyColor};

`;
// background-color: #f2f2f2;

export const Header = Styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction:row;
  justify-content:space-between;
  align-items:flex-end;
  width:100%;
  color: ${props => props.theme.headlineColor};
`;



export const Headline = Styled.div`
color: ${props => props.theme.headlineColor};

  @media ${VIEWPORT_XXS} {
    font-size: 1.375em;
  }

  @media ${VIEWPORT_XS} {
    font-size: 1.375em;
  }

  @media ${VIEWPORT_SM_MIN} {
    font-size: 1.375em;
  }
`;
