// enable developer tools and error messages in development
import 'preact/debug';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from '../App';
import { WidgetSizeProvider } from '../contexts/WidgetSize';
// this import is replaced with each widget and the resulting file is used as a separate entry point for webpack
import Form from '../organisms/LoanSealForm/LoanSealForm';
import { affiliateData, affiliateDataQueryString, combinedStyles, combinedVariables, widgetRoot, widgetVariables } from '../setup';
import { addStylesToSheet, createdStyleSheet } from '../util/addCustomStyles';
import ThemeHoc from '../util/ThemeHoc/ThemeHoc';


ReactDOM.render(
  <WidgetSizeProvider domNodeToObserve={widgetRoot}>
    <ThemeHoc enabled>
      <ThemeProvider theme={theme => ({ ...theme, ...combinedVariables, ...widgetVariables })}>
        <App affiliateData={{ ...affiliateData, ...affiliateDataQueryString }}>
          <Form />
        </App>
      </ThemeProvider>
    </ThemeHoc>
  </WidgetSizeProvider>,
  widgetRoot,
);

addStylesToSheet(createdStyleSheet)(combinedStyles);
